import React from "react";
import styles from "./FranchiseInternational.module.css";
import GradientText from "../../../../../components/GradientText/GradientText";
import international from "../../../../../assets/images/international.png";

const FranchiseInternational = () => {
	return (
		<section className={styles.section}>
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>I</span>NTERNATIONAL{" "}
							<span className={styles.big_letter}>F</span>RANCHISE{" "}
						</>
					}
				/>
			</div>
			<div className={styles.info_container}>
				<table>
					<tr>
						<td>Countries Open</td>
						<td>:</td>
						<td>
							•&nbsp;USA •&nbsp;Canada •&nbsp;UK •&nbsp;Europe •&nbsp;UAE
							•&nbsp;Australia •&nbsp;Singapore •&nbsp;Malaysia •&nbsp;Bangkok
						</td>
					</tr>
					<tr>
						<td>Franchise Fees</td>
						<td>:</td>
						<td>$&nbsp;25,000</td>
					</tr>
					<tr>
						<td>Fixed Royalty</td>
						<td>:</td>
						<td>$&nbsp;1500 /&nbsp;month</td>
					</tr>
					<tr>
						<td>Outlet Size</td>
						<td>:</td>
						<td>1500 - 2000 (Sq&nbsp;Ft)</td>
					</tr>
					<tr>
						<td>Setup Cost</td>
						<td>:</td>
						<td>$&nbsp;120,000</td>
					</tr>
					<tr>
						<td>ROI</td>
						<td>:</td>
						<td>12 - 16 Months</td>
					</tr>
				</table>
				<img src={international} alt="international" />
			</div>
		</section>
	);
};

export default FranchiseInternational;
