import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

const InformationAccordion = ({ summary, details }) => {
	return (
		<Accordion
			sx={{
				"&.Mui-expanded": {
					margin: "0px 8px",
				},
				"&.MuiPaper-root": {
					borderRadius: "8px",
				},
				background:
					"linear-gradient(269.51deg, rgba(139, 70, 0, 0.85) -4.52%, rgba(73, 37, 2, 0.85) 100%)",
				color: "white",
				width: "80%",
				margin: "0px 8px",

				"@media (max-width: 768px)": {
					margin: "12px 8px",
				},
			}}
		>
			<AccordionSummary
				sx={{
					"& .MuiAccordionSummary-content": {
						justifyContent: "center",

						"@media (max-width: 768px)": {
							fontSize: "14px",
							margin: "6px 0",
						},
					},

					"@media (max-width: 768px)": {
						minHeight: "unset",
					},
				}}
			>
				{summary}
			</AccordionSummary>
			<AccordionDetails
				sx={{
					fontSize: "14px",
					"@media (max-width: 768px)": {
						fontSize: "12px",
					},
				}}
			>
				{details}
			</AccordionDetails>
		</Accordion>
	);
};

export default InformationAccordion;
