import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import styles from "./ThumbPreviewGallery.module.css";
import gallery1 from "../../../../assets/images/gallery/gallery_1.jpeg";
import gallery2 from "../../../../assets/images/gallery/gallery_2.jpeg";
import gallery3 from "../../../../assets/images/gallery/gallery_3.jpeg";
import gallery4 from "../../../../assets/images/gallery/gallery_4.jpeg";
import gallery5 from "../../../../assets/images/gallery/gallery_5.jpeg";
import gallery6 from "../../../../assets/images/gallery/gallery_6.jpeg";
import gallery7 from "../../../../assets/images/gallery/gallery_7.jpeg";
import gallery8 from "../../../../assets/images/gallery/gallery_8.jpeg";
import gallery9 from "../../../../assets/images/gallery/gallery_9.jpeg";
import gallery10 from "../../../../assets/images/gallery/gallery_10.jpeg";
import gallery11 from "../../../../assets/images/gallery/gallery_11.jpeg";
import gallery12 from "../../../../assets/images/gallery/gallery_12.jpeg";
import gallery13 from "../../../../assets/images/gallery/gallery_13.jpeg";
import gallery14 from "../../../../assets/images/gallery/gallery_14.jpeg";
import gallery15 from "../../../../assets/images/gallery/gallery_15.jpeg";
import gallery16 from "../../../../assets/images/gallery/gallery_16.jpeg";

const items = [
	<img src={gallery1} alt="gallery1" className={styles.gallery_image} />,
	<img src={gallery2} alt="gallery2" className={styles.gallery_image} />,
	<img src={gallery3} alt="gallery3" className={styles.gallery_image} />,
	<img src={gallery4} alt="gallery4" className={styles.gallery_image} />,
	<img src={gallery5} alt="gallery5" className={styles.gallery_image} />,
	<img src={gallery6} alt="gallery6" className={styles.gallery_image} />,
	<img src={gallery7} alt="gallery7" className={styles.gallery_image} />,
	<img src={gallery8} alt="gallery8" className={styles.gallery_image} />,
	<img src={gallery9} alt="gallery9" className={styles.gallery_image} />,
	<img src={gallery10} alt="gallery10" className={styles.gallery_image} />,
	<img src={gallery11} alt="gallery11" className={styles.gallery_image} />,
	<img src={gallery12} alt="gallery12" className={styles.gallery_image} />,
	<img src={gallery13} alt="gallery13" className={styles.gallery_image} />,
	<img src={gallery14} alt="gallery14" className={styles.gallery_image} />,
	<img src={gallery15} alt="gallery15" className={styles.gallery_image} />,
	<img src={gallery16} alt="gallery16" className={styles.gallery_image} />,
];

const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
	return items.map((item, i) => (
		<div
			className={styles.thumb}
			style={{
				width: "min(20vw, 275px)",
				padding: "0 10px",
			}}
			onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
		>
			{item}
		</div>
	));
};

const ThumbPreviewCarousel = () => {
	const [mainIndex, setMainIndex] = useState(0);
	const [mainAnimation, setMainAnimation] = useState(false);
	const [thumbIndex, setThumbIndex] = useState(0);
	const [thumbAnimation, setThumbAnimation] = useState(false);
	const [thumbs] = useState(
		thumbItems(items, [setThumbIndex, setThumbAnimation])
	);

	const slideNext = () => {
		if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex + 1);
		}
	};

	const slidePrev = () => {
		if (!thumbAnimation && thumbIndex > 0) {
			setThumbAnimation(true);
			setThumbIndex(thumbIndex - 1);
		}
	};

	const syncMainBeforeChange = (e) => {
		setMainAnimation(true);
	};

	const syncMainAfterChange = (e) => {
		setMainAnimation(false);

		if (e.type === "action") {
			setThumbIndex(e.item);
			setThumbAnimation(false);
		} else {
			setMainIndex(thumbIndex);
		}
	};

	const syncThumbs = (e) => {
		setThumbIndex(e.item);
		setThumbAnimation(false);

		if (!mainAnimation) {
			setMainIndex(e.item);
		}
	};

	return [
		<AliceCarousel
			activeIndex={mainIndex}
			animationType="fadeout"
			animationDuration={800}
			disableDotsControls
			disableButtonsControls
			items={items}
			mouseTracking={!thumbAnimation}
			onSlideChange={syncMainBeforeChange}
			onSlideChanged={syncMainAfterChange}
			touchTracking={!thumbAnimation}
		/>,
		<div className={styles.thumbs}>
			<div className={styles.btn_prev} onClick={slidePrev}>
				&lang;
			</div>
			<AliceCarousel
				activeIndex={thumbIndex}
				disableDotsControls
				disableButtonsControls
				items={thumbs}
				mouseTracking={false}
				onSlideChanged={syncThumbs}
				touchTracking={!mainAnimation}
				autoWidth
			/>
			<div className={styles.btn_next} onClick={slideNext}>
				&rang;
			</div>
		</div>,
	];
};

export default ThumbPreviewCarousel;
