import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import OrderMain from './sections/OrderMain/OrderMain'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Cart from './sections/Cart/Cart';

export default function OrderPage() {
    const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('cartItems') ?? "[]") ?? [])
    const [cartOpen, setCartOpen] = useState(false)
    useEffect(() => {
        console.log(cartItems)
        if (cartItems) {
            localStorage.setItem("cartItems", JSON.stringify(cartItems ?? []))
        }
    }, [cartItems])
    return (

        <>
            <Header></Header>
            <OrderMain states={{ cartItems, setCartItems, cartOpen, setCartOpen }}></OrderMain>
            <Cart states={{ cartItems, setCartItems, cartOpen, setCartOpen }}></Cart>
        </>
    )
}
