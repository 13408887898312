import React from "react";
import kuchipudiFace from "../../../../assets/images/kuchipudi_face.png";
import styles from "./Home.module.css";
import foodEgg from "../../../../assets/images/food_egg.png";
import kuchipudiPalav from "../../../../assets/images/kuchipudi_palav.png";
import pipingHot from "../../../../assets/images/piping_hot.png";
import GradientText from "../../../../components/GradientText/GradientText";
import { MenuBookRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import whatsapp from "../../../../assets/icons/whatsapp.png";
import InformationAccordion from "../../../../components/InformationAccordion/InformationAccordion";

const Home = () => {
	return (
		<section className={styles.section} id="home">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							<span>
								{/* <span className={styles.big_letter}>W</span> */}
								WORLD'S{" "}
								{/* <span className={styles.big_letter}>F</span> */}
								FIRST
							</span>
							<span>
								{/* <span className={styles.big_letter}>C</span> */}
								CUSTOMISED{" "}
								{/* <span className={styles.big_letter}>P</span> */}
								PALAV
							</span>
						</div>
					}
					style={{
						fontFamily: "Times New Roman",
					}}
				/>
			</div>
			<div className={styles.image_container}>
				<div className={styles.side_image_container}>
					<img src={kuchipudiFace} alt="kuchipudi face" />
					<img src={pipingHot} alt="Piping Hot Rice" />
				</div>
				<img
					src={foodEgg}
					alt="food egg"
					onClick={() => {
						const e = document.getElementById("videos-section");
						const top = e.offsetTop;

						window.scrollTo({
							top:
								top -
								(window.matchMedia("(max-width: 768px)").matches ? 4 : 66),
							behavior: "smooth",
						});
					}}
				/>
				<div className={styles.side_image_container}>
					<img src={kuchipudiFace} alt="kuchipudi face" />
					<img src={kuchipudiPalav} alt="kuchipudi palav" />
				</div>
			</div>
			<div
				className={styles.text_container}
				onClick={() => {
					const e = document.getElementById("about-section");
					const top = e.offsetTop;

					window.scrollTo({
						top:
							top - (window.matchMedia("(max-width: 768px)").matches ? 4 : 66),
						behavior: "smooth",
					});
				}}
			>
				<img src={kuchipudiFace} alt="kuchipudi face" />
				<GradientText
					gradient="linear-gradient(#623508, #866C53)"
					text="Founder's Note"
					style={{
						borderBottom: "1px solid #866C53",
						marginLeft: "4px",
					}}
				/>
			</div>
			<div className={styles.cta_row}>
				<div
					className={styles.secondary_cta}
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "row",
						justifyContent: "center",
					}}
					onClick={() => {
						const e = document.getElementById("menu-section");
						const top = e.offsetTop;

						window.scrollTo({
							top:
								top -
								(window.matchMedia("(max-width: 768px)").matches ? 44 : 106),
							behavior: "smooth",
						});
					}}
				>
					<MenuBookRounded
						sx={{
							marginRight: "8px",
						}}
					/>
					<Typography
						variant="h6"
						component="div"
						sx={{
							"@media (max-width: 768px)": {
								fontFamily: "Times New Roman",
							},
						}}
					>
						Menu
					</Typography>
				</div>
				<div
					className={styles.primary_cta}
					onClick={() => {
						const e = document.getElementById("franchise-section");
						const top = e.offsetTop;

						window.scrollTo({
							top:
								top -
								(window.matchMedia("(max-width: 768px)").matches ? 4 : 66),
							behavior: "smooth",
						});
					}}
				>
					Franchise at $25,000
				</div>
				<div
					className={styles.secondary_cta}
					style={{
						fontSize: "12px",
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
							cursor: "pointer",
						}}
						onClick={() => {
							window.open(
								"https://wa.me/919032355522?text=I am interested to know more about the Franchise!",
								"_blank"
							);
						}}
					>
						<img
							src={whatsapp}
							alt="whatsapp"
							style={{
								width: "20px",
								height: "20px",
								marginRight: "4px",
							}}
						/>
						(91) 90323 55522
					</div>
					<div
						style={{
							cursor: "pointer",
						}}
						onClick={() => {
							window.open(
								"mailto:kuchipudipalav@gmail.com?subject=Franchise Enquiry&body=I am interested to know more about the Franchise!",
								"_blank"
							);
						}}
					>
						kuchipudipalav@gmail.com
					</div>
				</div>
			</div>
			<div className={styles.info_row}>
				<InformationAccordion
					summary="World's Lowest Breakeven Franchise"
					details="Any business needs to have an operational breakeven to start with and then the investment breakeven. Kuchipudi Palav/Piping Hot Rice models have the lowest operational and quickest investment breakeven in the world. For an international model at an average fixed cost (rent, salaries & operational costs) of 25,000 USD, the operational breakeven is around 600 USD/day or 18,000 USD/month. ROI can be achieved within 16 months with an average sale of 30,000 USD/month."
				/>
				<InformationAccordion
					summary="100% Chefless Model"
					details="
					Life is made easy at kuchipudi palav/piping hot rice,these restaurant models don't need a professional chef it can be run by any walk in hired employee with hardly any skill.
					All they need to do is to follow our instruction manuals and videos to dish out the most delicious food of your choice."
				/>
				<InformationAccordion
					summary="World's First Customised Restaurant"
					details="
					At KUCHIPUDI PALAV/PIPING HOT RICE restros the guests have a wide variety and choices to choose from with a great scope of customizing the food to their taste and feel. It's a made to order concept where you get to choose a choice of flavor/base...choice of add-ons like meats/veggies and then the style of preparation i.e grilled fried steamed... served piping hot."
				/>
			</div>
		</section>
	);
};

export default Home;
