import React from "react";
import styles from "./About.module.css";
import venkatKuchipudi from "../../../../assets/images/venkat_kuchipudi.png";
import GradientText from "../../../../components/GradientText/GradientText";

const About = () => {
	return (
		<section className={styles.section} id="about-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>F</span>OUNDER'S{" "}
							<span className={styles.big_letter}>N</span>OTE{" "}
						</>
					}
				/>
			</div>
			<img src={venkatKuchipudi} alt="venkat kuchipudi" />
			<div className={styles.description}>
				<p>
					I always believed that Indian spices are endowed with mystique. With
					the right blend and mix, they can bring out priceless and addictive
					results. The most heart-winning dish with a myriad mix of these
					mysterious spices can only be the PALAV/BIRYANI. The major challenge I
					had was reducing the complexity of preparing this world-famous dish
					and making it effortless with our unique patented recipe. As a brand,
					we are delivering a simple technique to not only easily cook a complex
					delicacy that is the Palav/Biryani, but also create an opportunity for
					anyone to go into business even without any prior food industry
					experience or dependence on the skill of chefs/consultants. My goal
					was to create a chef-less and skill-less restaurant model which is
					easily scalable unlike conventional models. It took a great deal of
					effort, time (almost a decade of my prime), money, and relentless
					perseverance against all odds, to finally make possible projects which
					were considered impossible – the Maredumilli Forest Grill restaurant
					experience and the Kuchipudi Palav. I hope my efforts will make life
					easier for many prospective entrepreneurs to realize their dream of
					owning and running a restaurant effortlessly.
				</p>
				<div className={styles.author_block}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							color: "#822F00",
						}}
					>
						<div className={styles.author_name}>Venkat Kuchipudi</div>
						<div className={styles.author_desig}>Founder</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
