import React from "react";
import styles from "./Footer.module.css";
import shakespeare from "../../assets/icons/shakespeare.png";
import GradientText from "../GradientText/GradientText";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className={styles.footer}>
			<div className={styles.divider} />
			<div
				className={styles.footer_content}
				onClick={() => {
					window.open("https://business.shakespeare.vision", "_blank");
				}}
			>
				<img src={shakespeare} alt="shakespeare" />
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<div>
							<div
								style={{
									fontSize: "14px",
									fontFamily: "Times New Roman",
								}}
							>
								Powered By
							</div>
							<div>Shakespeare</div>
						</div>
					}
				/>
			</div>
			<b className={styles.address_header}>Address</b>
			<div className={styles.address}>
				<div>
					<b>Kuchipudi Palav Nizampet</b>
					<span>Plot no98, ground floor, Prashant Nagar colony, Hi tension road line, Nizampet Kukatpally, Hyderabad-500085 Opp to Vijaya Diagnostic Centre.</span>
					<div>Ph: <a href="tel:9281455860">9281455860</a>, <a href="tel:9281455861">9281455861</a> </div>
				</div>
				<div>
					<b>Kuchipudi Palav Film Nagar </b>
					<span>8-2-293, First Floor, 82/F/73-A, Rd Number 82, above Concu Patisserie, Film Nagar, Hyderabad, Telangana 500096.</span>
					<div>Ph: <a href="tel:9032383366">9032383366</a>, <a href="tel:9032393366">9032393366</a> </div>

				</div>
			</div>
			<b className={styles.help_header}>Help & Guide</b>
			<div className={styles.help_items}>
				<div>
					<Link to="/privacy">Privacy Policy</Link>
					<Link to="/terms">Terms Of Use</Link>
					<Link to="/shipping-and-delivery-policy">Shipping & Delivery</Link>
				</div>
			</div>
		</div>
	);
};

export default Footer;
