import React, { useEffect, useState } from 'react'
import styles from './OrderMain.module.css'
import boxImg from '../../../../assets/images/boxes.png'
import GradientText from '../../../../components/GradientText/GradientText'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import getUserCountry from "js-user-country";

export default function OrderPage({ states }) {
    const { cartItems, setCartItems, setCartOpen } = states;
    const addToCart = (item) => {
        const itemIndex = cartItems.findIndex(it=>{
            return it._id===item._id
        });
        if(itemIndex<0){
            setCartItems([{...item, quantity:1},...cartItems])
            toast.success("Item Added to cart")
        }else{
            let cartItemsTemp=cartItems;
            cartItemsTemp[itemIndex].quantity++;
            setCartItems([...cartItemsTemp])
        }
        setCartOpen(true)
    }
    const [products, setProducts] = useState([])
    const fetchProducts = async()=>{
        try{
            const {data} = await axios.get("https://shkspr24632.in/api/kuchipudi");
            if(getUserCountry().name==="India"){
                setProducts(data)
            }else{
                const updatedData=data.map((item, index)=>{
                    return {...item, price:item.globalPrice, currencySymbol:"$", currency:"USD"}
                })
                setProducts(updatedData)
            }
            console.log(data);
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        fetchProducts()
    },[])
    return (
        <div className={styles.section}>

            {/* <div className={styles.order_body}>
                <img src={kuchipudiMasalaImg} alt="" />
            </div> */}
            <div className={styles.title}>
                <GradientText
                    gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
                    text="BUY NOW"
                    style={{ marginTop: "50px" }}
                />
            </div>
            <div className={styles.options}>
                {
                    products.map((product, index)=>{
                        return <div key={index} onClick={() => addToCart(product)} className={styles.option}>
                        <img src={boxImg} alt="" />
                        <b>{product.name}</b>
                        <h2>{product.currencySymbol+product.price}</h2>
                    </div>
                    })
                }
            </div>
        </div>
    )
}
