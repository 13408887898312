import React from 'react'
import Header from '../../components/Header/Header'
import styles from '../OrderPage/sections/OrderMain/OrderMain.module.css'
import MakingVideos from '../Home/sections/MakingVideos/MakingVideos'

const kuchipudiOrderInstructionImg = "https://res.cloudinary.com/nothingstorage/image/upload/v1715534714/IMG-20240501-WA0053_bjoyxf.jpg"

export default function OrderInstructionPage() {
    return (
        <>
            <Header></Header>
            <div className={styles.section+" "+styles.instruction_sec} style={{backgroundColor:"#B80606"}}>
                <div className={styles.order_body}>
                    <img src={kuchipudiOrderInstructionImg} alt="" />   
                </div>
                <MakingVideos></MakingVideos>
            </div>
        </>
    )
}
