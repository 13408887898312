import React from "react";
import styles from "./MakingVideos.module.css";
import GradientText from "../../../../components/GradientText/GradientText";

const MakingVideos = () => {
	return (
		<section className={styles.section} id="making-videos-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>P</span>REPARATION{" "}
							<span className={styles.big_letter}>V</span>IDEOS{" "}
						</>
					}
				/>
			</div>
			<div className={styles.video_container}>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/8lBa_K8W9NM"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/8Km7GNXSIOw"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</section>
	);
};

export default MakingVideos;
