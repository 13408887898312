import React from 'react'
import styles from './Cart.module.css'

export default function Counter({handleIncrement, handleDecrement, value}) {
  return (
    <div className={styles.counter}>
        <button onClick={handleDecrement}>-</button>
        <div>{value}</div>
        <button onClick={handleIncrement}>+</button>
    </div>
  )
}
