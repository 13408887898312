import React, { useEffect } from "react";
import styles from "./OrderSection.module.css";
import GradientText from "../../../../components/GradientText/GradientText";
import freeShippingIcon from '../../../../assets/icons/free-shipping.png'
import instructionIcon from '../../../../assets/icons/manual-book.png'
import orderIcon from '../../../../assets/icons/order.png'
import { Link } from "react-router-dom";


const OrderSection = () => {
    // let kuchipudiMasalaImg="https://res.cloudinary.com/nothingstorage/image/upload/v1715534714/IMG-20240508-WA0001_iswqsg.jpg"
    // if(getUserCountry().name==="India"){
    //     kuchipudiMasalaImg="https://res.cloudinary.com/nothingstorage/image/upload/v1715534714/IMG-20240512-WA0177_nxi5lv.jpg"
    // }
    return (
        <section className={styles.section} id="order-section">
            <div className={styles.title}>
                <GradientText
                    gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
                    text="KUCHIPUDI PALAV MASALA (RAJU GARI KODI PALAV)"
                />
            </div>
            <div className={styles.body}>
                {/* <div className={styles.img_sec}>
                    <img src={kuchipudiMasalaImg} alt="" />
                </div> */}

                <div className={styles.body_sec}>


                    <div className={styles.options}>
                        <Link to={'/order'} className={styles.option}>
                            <img src={orderIcon} alt="" />
                            <b>Order Now</b>
                        </Link>
                        <Link to={'/order/instructions'} className={styles.option}>
                            <img src={instructionIcon} alt="" />
                            <b>Cooking Instructions</b>
                        </Link>
                        <Link to={'/'} className={styles.option}>
                            <img src={freeShippingIcon} alt="" />
                            <b>Free Delivery</b>
                        </Link>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default OrderSection;
