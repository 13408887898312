import React, { useState } from 'react';
import styles from './Navbar.module.css'
import kuchipudiPalav from "../../../../assets/images/kuchipudi_palav.png";
import kuchipudiFace from "../../../../assets/images/kuchipudi_face.png";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const navigate= useNavigate()
  const goToSection=(e, page, section)=>{
    e.preventDefault();
    navigate("/"+page+"#"+section);
    if(document.getElementById(section)){  
      window.scrollTo({
        top: document.getElementById(section)?.offsetTop-(window.screen.width<= 768 ? 100 : 160),
        behavior: "smooth"
      });
      setOpenNav(!openNav);
    }
}
  const [openNav, setOpenNav] = useState(false);

  return (
    <MDBNavbar expand='lg' className={'w-100 '+styles.navbar} light>
      <MDBContainer>
        <MDBNavbarBrand onClick={(e)=>goToSection(e,"",'')}>
          <img src={kuchipudiFace} height={40} alt="" />
          <img src={kuchipudiPalav} height={40} alt="" />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenNav(!openNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar open={openNav}>
          <MDBNavbarNav className={styles.navbarNav}>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'home')}>Home</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"order",'')}>Shopping</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'menu-section')}>Menu</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'about-section')}>About</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'franchise-section')}>Franchise Models</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'grand-launch-section')}>Grand Launch</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'gallery-section')}>Gallery</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"order/instructions",'')}>Cooking Instructions</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"order/instructions",'making-videos-section')}>Preparation Videos</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={(e)=>goToSection(e,"",'contact-section')}>Contact</MDBNavbarLink>
            </MDBNavbarItem>
          
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}