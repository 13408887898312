import React from "react";
import styles from "./Menu.module.css";
import { Typography } from "@mui/material";
import menu1 from "../../../../assets/images/menu_1.jpeg";
import menu2 from "../../../../assets/images/menu_2.png";
import GradientText from "../../../../components/GradientText/GradientText";

const Menu = () => {
	return (
		<section className={styles.section} id="menu-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text="MENU"
				/>
			</div>
			<div className={styles.image_container}>
				<img src={menu1} alt="menu 1" />
				<img src={menu2} alt="menu 2" />
			</div>
		</section>
	);
};

export default Menu;
