import React from "react";
import styles from "./FranchiseIndian.module.css";
import GradientText from "../../../../../components/GradientText/GradientText";
import indian from "../../../../../assets/images/indian.png";

const FranchiseIndian = () => {
	return (
		<section className={styles.section}>
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>I</span>NDIAN{" "}
							<span className={styles.big_letter}>F</span>RANCHISE{" "}
						</>
					}
				/>
			</div>
			<div className={styles.info_container}>
				<table>
					<tr>
						<td>Franchise Cost</td>
						<td>:</td>
						<td>₹5,00,000 +GST</td>
					</tr>
					<tr>
						<td>Food Court Model</td>
						<td>:</td>
						<td>₹2,50,000 +GST</td>
					</tr>
					<tr>
						<td>Fixed Royalty</td>
						<td>:</td>
						<td>₹&nbsp;10000 /&nbsp;month</td>
					</tr>
					<tr>
						<td>Outlet Size</td>
						<td>:</td>
						<td>1000 (Sq&nbsp;Ft)</td>
					</tr>
					<tr>
						<td>Digital Marketing Promotion</td>
						<td>:</td>
						<td>₹&nbsp;2,500</td>
					</tr>
					<tr>
						<td>ROI</td>
						<td>:</td>
						<td>12-16 Months</td>
					</tr>
				</table>
				<img src={indian} alt="indian" />
			</div>
			
		</section>
	);
};

export default FranchiseIndian;
