import React from "react";
import kuchipudiFace from "../../assets/images/kuchipudi_face.png";
import kuchipudiPalav from "../../assets/images/kuchipudi_palav.png";
import pipingHot from "../../assets/images/piping_hot.png";
import styles from "./Header.module.css";
import GradientText from "../../components/GradientText/GradientText";
import Navbar from "../../pages/Home/sections/Banner/Navbar";

const Header = () => {
	return (
		<>
		<header className={styles.header}>
			<div className={styles.header_image}>
				<img src={kuchipudiFace} alt="kuchipudi face" />
				<img src={pipingHot} alt="Piping Hot Rice" />
			</div>
			<GradientText
				gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
				text={
					<>
						<span className={styles.big_letter}>W</span>ORLD'S{" "}
						<span className={styles.big_letter}>F</span>IRST{" "}
						<span className={styles.big_letter}>C</span>USTOMISED{" "}
						<span className={styles.big_letter}>P</span>ALAV{" "}
					</>
				}
				style={{
					fontSize: "2.2rem",
					fontFamily: "Times New Roman",
					textAlign: "center",
				}}
			/>
			<div className={styles.header_image}>
				<img src={kuchipudiFace} alt="kuchipudi face" />
				<img src={kuchipudiPalav} alt="kuchipudi palav" />
			</div>
		</header>
		<Navbar></Navbar>
				</>
	);
};

export default Header;
