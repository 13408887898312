import {
	CallRounded,
	ChatRounded,
	Instagram,
	MailRounded,
	MenuBookRounded,
	Twitter,
	WhatsApp,
} from "@mui/icons-material";
import { Fab, List, ListItem, Popover, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./ContactFAB.module.css";

const ContactFAB = () => {
	const fabRef = useRef(null);

	const [showMenu, setShowMenu] = useState(false);

	return (
		<>
			<Fab
				translate="no"
				ref={fabRef}
				sx={{
					position: "fixed",
					bottom: "20px",
					right: "20px",
					zIndex: "1000",
					background:
						"linear-gradient(225deg, hsla(30, 100%, 27%, 1) 0%, hsla(30, 95%, 15%, 1) 100%)",
					color: "white",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "60px",
					height: "60px",
					"&:hover": {
						background:
							"linear-gradient(225deg, hsla(30, 100%, 27%, 1) 0%, hsla(30, 95%, 15%, 1) 100%)",
					},

					"@media (max-width: 760px)": {
						flexDirection: "row",
						width: "auto",
						height: "auto",
						padding: "8px 10px",
						borderRadius: "25px",

						svg: {
							marginRight: "8px",
						},
					},
				}}
				onClick={() => setShowMenu(true)}
			>
				<ChatRounded fontSize="medium" />
				<Typography
					variant="caption"
					component="div"
					sx={{
						fontSize: "10px",
					}}
				>
					CONTACT
				</Typography>
			</Fab>
			<Popover
				open={showMenu}
				anchorEl={fabRef.current}
				onClose={() => setShowMenu(false)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					"& .MuiPopover-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
						borderRadius: "20px",
					},
				}}
			>
				<List className={styles.list}>
					<ListItem
						onClick={() => {
							window.open(
								"https://wa.me/919032355522?text=I am interested to know more about the New Masala!",
								"_blank"
							);
						}}
					>
						<WhatsApp />
						Whatsapp
					</ListItem>
					<ListItem
						onClick={() => {
							window.open(
								"mailto:order@kuchipudipalav.com?subject=Franchise Enquiry&body=I am interested to know more about the Franchise!",
								"_blank"
							);
						}}
					>
						<MailRounded />
						Mail
					</ListItem>
					<ListItem
						onClick={() => {
							window.open("tel:+919032355522", "_blank");
						}}
					>
						<CallRounded />
						US-Line
					</ListItem>
					{/* <ListItem>
						<Twitter />
						Twitter
					</ListItem> */}
				</List>
			</Popover>
		</>
	);
};

export default ContactFAB;
