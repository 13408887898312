import React, { useState } from "react";
import { toast } from "react-hot-toast";

import styles from "./ContactForm.module.css";
import GradientText from "../../../../components/GradientText/GradientText";

const ContactForm = () => {
	const [inputs, setInputs] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;	
		setInputs({ ...inputs, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if (!inputs.name || !inputs.email || !inputs.phone || !inputs.city) {
				toast.error("Please fill all the fields!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				return;
			} else if (
				!inputs.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
			) {
				toast.error("Please enter a valid email address!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				return;
			} else if (!inputs.phone.match("^[0-9]{10}$")) {
				toast.error("Please enter a valid phone number!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				return;
			}

			window.open(
				`mailto:kuchipudipalav@gmail.com?subject=Franchise Enquiry&body=Name: ${inputs.name}%0D%0AEmail: ${inputs.email}%0D%0APhone: ${inputs.phone}%0D%0ACity: ${inputs.city}
				%0D%0AI am interested to know more about the Franchise!`,
				"_blank"
			);

			setInputs({});
		} catch (err) {
			toast.error("Something went wrong, please try again later.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});
		}
	};

	return (
		<section className={styles.section} id="contact-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>G</span>ET{" "}
							<span className={styles.big_letter}>I</span>N{" "}
							<span className={styles.big_letter}>T</span>OUCH{" "}
						</>
					}
				/>
			</div>
			<div className={styles.form}>
				<div className={styles.form_title}>Please fill your Information:</div>
				<div className={styles.floating_label_group}>
					<input
						name="name"
						type="text"
						value={inputs.name || ""}
						required={true}
						onChange={handleChange}
					/>
					<label className={styles.floating_label}>Name</label>
				</div>
				<div className={styles.floating_label_group}>
					<input
						name="email"
						type="text"
						value={inputs.email || ""}
						required={true}
						onChange={handleChange}
					/>
					<label className={styles.floating_label}>Email</label>
				</div>
				<div className={styles.floating_label_group}>
					<input
						name="phone"
						type="number"
						min={0}
						maxLength={10}
						value={inputs.phone || ""}
						required={true}
						onChange={handleChange}
					/>
					<label className={styles.floating_label}>Phone</label>
				</div>
				<div className={styles.floating_label_group}>
					<input
						name="city"
						type="text"
						value={inputs.city || ""}
						required={true}
						onChange={handleChange}
					/>
					<label className={styles.floating_label}>City of Interest</label>
				</div>
				<button className={styles.submit_button} onClick={handleSubmit}>
					Submit
				</button>
			</div>
		</section>
	);
};

export default ContactForm;
