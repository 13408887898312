import React from "react";
import styles from "./OtherSuccesses.module.css";
import GradientText from "../../../../components/GradientText/GradientText";
import maredumilli from "../../../../assets/images/maredumilli.png";
import rajugaadiThota from "../../../../assets/images/rajugaari_thota.png";

const OtherSuccesses = () => {
	return (
		<section className={styles.section}>
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>O</span>THER{" "}
							<span className={styles.big_letter}>S</span>UCCESSES{" "}
						</>
					}
				/>
			</div>
			<div className={styles.image_container}>
				<a href="https://g.co/kgs/aegcbT" target="_blank">
					<img src={maredumilli} alt="maredumilli" />
				</a>
				<a href="https://g.co/kgs/3GUFwK" target="_blank">
					<img src={rajugaadiThota} alt="rajugaadi thota" />
				</a>
			</div>
		</section>
	);
};

export default OtherSuccesses;
