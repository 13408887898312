import React, { useEffect, useState } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import styles from './Cart.module.css'
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SignupForm from './SignupForm';
import Counter from './Counter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function Cart({ states }) {
    const drawerAnchor= window.screen.width<= 768 ? "bottom" : "right";
    const { cartItems, setCartItems, cartOpen, setCartOpen } = states;
    const [open, setOpen] = useState(false);
    const [checkoutOpen, setCheckoutOpen] = useState(false)
    const toggleDrawer = (newOpen) => () => {
        setCartOpen(newOpen);
    };
    const handleIncrement = (index) => {
        let cartItemsTemp = cartItems;
        cartItemsTemp[index].quantity++;
        setCartItems([...cartItemsTemp])
    }
    const handleDecrement = (index) => {
        let cartItemsTemp = cartItems;
        if (cartItems[index].quantity <= 1) {
            cartItemsTemp = cartItemsTemp.filter((item, filterIndex) => index != filterIndex);
            setCartItems([...cartItemsTemp])
        } else {
            cartItemsTemp[index].quantity--;
        }
        setCartItems([...cartItemsTemp])
    }
    const DrawerList = (
        <Box sx={{ width: "500px", maxWidth: "100vw", backgroundColor: "#FEF2E2", height: "100%", paddingBottom:"60px" }} role="presentation" >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setCartOpen(false)}>
                        <ListItemIcon>
                            <ChevronLeftIcon sx={{ color: "#822F00" }} />
                        </ListItemIcon>
                        <b style={{ color: "#822F00" }} >Cart</b>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            {
                !cartItems?.[0] &&
                <div style={{ display: "flex", justifyContent: 'center', marginTop: "20px" }}>
                    <b>Cart is Empty</b>
                </div>
            }
            <List sx={styles.cartItems}>
                {(cartItems ?? []).map((item, index) => (
                    <ListItem key={item.name} >
                        <div className={styles.cartItem}>
                            <span>{item.name}</span>
                            <Counter
                                value={item.quantity}
                                handleIncrement={() => handleIncrement(index)}
                                handleDecrement={() => handleDecrement(index)}
                            ></Counter>
                            <b>{item.currencySymbol}{item.price * item.quantity}</b>
                        </div>
                    </ListItem>
                ))}
                <Divider sx={{ marginTop: "15px" }} />
                <ListItem >
                    <div className={styles.cartItem}>
                        <span>Total</span>
                        <div></div>
                        <b>{cartItems[0]?.currencySymbol}{cartItems.reduce((acc, item) => {
                            return acc + (item.price * item.quantity)
                        }, 0)}</b>
                    </div>
                </ListItem>
            </List>
            {
                cartItems[0] &&
                <div className={styles.btn}>
                    <button onClick={() => setCheckoutOpen(true)} className={styles.checkout_btn + ' primary_btn'}>
                        Checkout
                    </button>
                </div>
            }
        </Box>
    );
    return (
        <div style={styles.cart_main}>
            {
                cartItems[0] &&
                <button onClick={toggleDrawer(true)} className={styles.cart_button}>
                    <ShoppingCartIcon />
                    <div className={styles.cart_count}>{cartItems.length}</div>
                </button>
            }
            <Drawer open={cartOpen} anchor={drawerAnchor} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            <SignupForm open={checkoutOpen} closeCart={() => setOpen(false)} setCartItems={setCartItems} setOpen={setCheckoutOpen}></SignupForm>
        </div>
    )
}
