import React from 'react'
import styles from './Banner.module.css'
import getUserCountry from 'js-user-country'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'


export default function Banner() {
    const navigate= useNavigate()
    let kuchipudiMasalaImg = "https://res.cloudinary.com/nothingstorage/image/upload/v1715534714/IMG-20240508-WA0001_iswqsg.jpg"
    if (getUserCountry().name === "India") {
        kuchipudiMasalaImg = "https://res.cloudinary.com/nothingstorage/image/upload/v1715534714/IMG-20240512-WA0177_nxi5lv.jpg"
    }

    const scrollToOrder=()=>{
            window.scrollTo({
                top: document.getElementById("order-section")?.offsetTop,
                behavior: "smooth"
            });
    }
    return (
        <div className={styles.section}>
            {/* <Navbar></Navbar> */}
            <div className={styles.container}>
                <div className={styles.details}>
                    <h1>KUCHIPUDI PALAV MASALA NOW AVAILABLE</h1>
                    <p>Make the world's hottest palav at the comfort of your home</p>
                    <div className={styles.btns}>
                        <button onClick={scrollToOrder} className='secondary_btn'>Explore</button>
                        <button onClick={()=>navigate("/order")} className='primary_btn'>Buy Now</button>
                    </div>
                </div>
                <div className={styles.image_container}>
                        <img src={kuchipudiMasalaImg} alt="" />

                </div>
            </div>
        </div>
    )
}
