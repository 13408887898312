import React from "react";
import styles from "./Gallery.module.css";
import GradientText from "../../../../components/GradientText/GradientText";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ThumbPreviewCarousel from "./ThumbPreviewGallery";

const Gallery = () => {
	return (
		<section className={styles.section} id="gallery-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>P</span>HOTO{" "}
							<span className={styles.big_letter}>G</span>ALLERY{" "}
						</>
					}
				/>
			</div>
			<ThumbPreviewCarousel />
		</section>
	);
};

export default Gallery;
