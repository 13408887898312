import React from "react";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css'
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./pages/Home/HomePage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import OrderSection from "./pages/Home/sections/OrderSection/OrderSection";
import OrderPage from "./pages/OrderPage/OrderPage";
import axios from "axios";
import OrderInstructionPage from "./pages/OrderInstructionPage/OrderInstructionPage";
import { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./pages/Terms/Terms";
import ShippingAndDeliveryPolicy from "./pages/ShippingAndDeliveryPolicy/ShippingAndDeliveryPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomePage />,
	},
	{
		path: "/order",
		element: <OrderPage />,
	},
	{
		path: "/order/instructions",
		element: <OrderInstructionPage />,
	},
	{
		path: "/privacy",
		element: <PrivacyPolicy />,
	},
	{
		path: "/terms",
		element: <Terms />,
	},
	{
		path: "/shipping-and-delivery-policy",
		element: <ShippingAndDeliveryPolicy />,
	},
]);
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
		<Toaster
			position="top-right"
			reverseOrder={false}
		/>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
