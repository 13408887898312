import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import Home from './sections/Home/Home'
import Menu from "./sections/Menu/Menu";
import About from "./sections/About/About";
import FranchiseIntro from './sections/Franchise/Intro/FranchiseIntro'
import OtherSuccesses from "./sections/OtherSuccesses/OtherSuccesses";
import GrandLaunch from "./sections/GrandLaunch/GrandLaunch";
import ContactForm from "./sections/ContactForm/ContactForm";
import OverseasPartner from "./sections/OverseasPartner/OverseasPartner";
import Gallery from "./sections/Gallery/Gallery";
import ContactFAB from "../../components/ContactFAB/ContactFAB";
import Footer from "../../components/Footer/Footer";
import MakingVideos from "./sections/MakingVideos/MakingVideos";
import OrderSection from './sections/OrderSection/OrderSection';
import Banner from './sections/Banner/Banner';
export default function HomePage() {
	const scrollId = window.location.href.split("#")[1];
	useEffect(() => {
		if (document.getElementById(scrollId)) {
			window.scrollTo({
				top: document.getElementById(scrollId)?.offsetTop,
				behavior: "smooth"
			});
		}
	}, [])
	return (
		<div>
			<Header />
			<Banner />
			<OrderSection />
			<Home />
			<Menu />
			<FranchiseIntro />
			<OtherSuccesses />
			<About />
			{/* <OverseasPartner /> */}
			<GrandLaunch />
			<Gallery />
			<MakingVideos />
			<ContactForm />
			<Footer />
			<ContactFAB />
		</div>
	)
}
