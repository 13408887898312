import React from "react";
import styles from "./GrandLaunch.module.css";
import GradientText from "../../../../components/GradientText/GradientText";
import grandLaunch1 from "../../../../assets/images/grand_launch_1.png";
import grandLaunch2 from "../../../../assets/images/grand_launch_2.png";
import grandLaunch3 from "../../../../assets/images/grand_launch_3.png";
import grandLaunch4 from "../../../../assets/images/grand_launch_4.png";

const GrandLaunch = () => {
	return (
		<section className={styles.section} id="grand-launch-section">
			<div className={styles.title}>
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text={
						<>
							<span className={styles.big_letter}>G</span>RAND{" "}
							<span className={styles.big_letter}>L</span>AUNCH{" "}
						</>
					}
				/>
			</div>
			<div className={styles.description}>
				Kuchipudi Palav was grandly launched in Pennsylvania Convention Center,
				USA by renowned producer Dil Raju Garu & Hon'ble Minister for Panchayat
				Raj and Rural Development, Rural Water Supply for Telangana, Errabelli
				Dayakar Rao Garu.
			</div>
			<div className={styles.video_container}>
				<div>
					<iframe
						width="560"
						height="376"
						src="https://www.youtube.com/embed/R9HUVrmhvQg"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
					<img src={grandLaunch1} alt="grand launch 1" />
					<img src={grandLaunch2} alt="grand launch 2" />
					<img src={grandLaunch3} alt="grand launch 3" />
					<img src={grandLaunch4} alt="grand launch 4" />
				</div>
				<iframe
					width="392"
					height="697"
					src="https://www.youtube.com/embed/mh3rQUxNDgs"
					title="Producer Dil Raju Inaugurating Kuchipudi Palav in USA"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</section>
	);
};

export default GrandLaunch;
