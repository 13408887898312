import React, { useState } from "react";
import styles from "./FranchiseIntro.module.css";
import GradientText from "../../../../../components/GradientText/GradientText";
import FranchiseInternational from "../International/FranchiseInternational";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import applicationForm from "../../../../../assets/files/Franchise-Application-Form.pdf";
import { toast } from "react-hot-toast";
import FranchiseIndian from "../Indian/FranchiseIndian";

const FranchiseIntro = () => {
	const [applicationFormOpen, setApplicationFormOpen] = useState(false);

	return (
		<>
			<section className={styles.section} id="franchise-section">
				<div className={styles.title}>
					<GradientText
						gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
						text={
							<>
								<span className={styles.big_letter}>F</span>RANCHISE{" "}
								<span className={styles.big_letter}>M</span>ODELS{" "}
							</>
						}
					/>
				</div>
				<div className={styles.intro_container}>
					<div className={styles.subtitle}>
						MAKE YOUR RESTAUATEUR DREAM POSSIBLE...
					</div>
					<p className={styles.description}>
						Palav/Biryani is the most dominant and single largest selling dish
						of Indian cuisine in the world . We, here at Kuchipudi Palav/Piping
						hot rice, wish to take this unique taste and recipe all over the
						world with a simple and easy end to end solution. We offer a
						complete package which enables anyone to set up their own franchise
						restaurant, food joint or cloud kitchen anywhere in the world,
						without compromising on the quality and taste. Kuchipudi
						Palav/Piping hot rice is made from high quality ingredients to
						deliver outstanding quality, appetizing aroma and an incredible
						taste. Our business model comes with an assured ROI. Here is your
						first step to own your own food joint even without any prior
						experience or knowledge in the food business.
					</p>
					<FranchiseInternational />
					<FranchiseIndian />
					<button
						className={styles.submit_button}
						onClick={() => {
							setApplicationFormOpen(true);
						}}
					>
						Apply for Franchise
					</button>
				</div>
			</section>
			<Dialog
				open={applicationFormOpen}
				onClose={() => setApplicationFormOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "#FEF5E5",
						boxShadow: "none",
						borderRadius: "20px",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<DialogTitle
					sx={{
						"&.MuiTypography-root": {
							fontFamily: "Times New Roman",
							fontSize: "2rem",

							"@media (max-width: 768px)": {
								fontSize: "20px",
							},
						},
					}}
				>
					<GradientText
						gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
						text={
							<>
								<span className={styles.big_letter}>A</span>PPLICATION{" "}
								<span className={styles.big_letter}>F</span>ORM{" "}
							</>
						}
					/>
				</DialogTitle>
				<DialogContent
					sx={{
						"&.MuiDialogContent-root": {
							"@media (max-width: 768px)": {
								textAlign: "center",
							},
						},
					}}
				>
					Please download, fill, sign and revert the application form to{" "}
					<a href="mailto:kuchipudipalav@gmail.com">kuchipudipalav@gmail.com</a>
				</DialogContent>
				<DialogActions
					sx={{
						"&.MuiDialogActions-root": {
							justifyContent: "center",
						},
					}}
				>
					<button
						className={styles.submit_button}
						style={{
							marginTop: "0px",
							marginBottom: "20px",
						}}
						onClick={() => {
							// window.open(applicationForm, "_blank");
							toast.info("Franchise Application Coming Soon!", {
								position: "top-right",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								draggable: true,
								pauseOnHover: false,
								theme: "colored",
							});
						}}
					>
						Download Form
					</button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FranchiseIntro;
